import React from 'react'
import './future.css'

const ThemeFort = () => {
  return (
    <div className='future'>
        <h1>FUTURE PROJECTS</h1>
        <p>Fort Annexe</p>
        {/* <hr/> */}
        <p>Kokapet Commercial Complex</p>
        {/* <hr/> */}
        <p>Kajaguda</p>
        {/* <hr/> */}
        <p>Tellapur Residential</p>
        {/* <hr/> */}
        <p>	Fort Vista</p>
        {/* <hr/> */}
        <p>	Crest</p>
    </div>
  )
}

export default ThemeFort