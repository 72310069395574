export const InfoData=[
    {
        info:'Making Dreams come true'
    },
    {
        info:'Your Dream Our Expertise'
    },
    {
        info:'All Smart homes'
    },
    {
        info:'Making Dreams come true mock living environment'
    }
]