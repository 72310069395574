

export const ImageData =[
    {
        image:'./images2/carouselPic1.jpg'
    },
    {
        image:'./images2/carouselPic2.jpg'
    },
    {
        image:'./images2/BB REALTY-01.jpg'
    }
    // ,
    // {
    //     image:'https://cdn.theatlantic.com/thumbor/Iff9TkXlgMyEbs8wK6JeGUbHK0w=/0x57:1088x669/960x540/media/img/mt/2015/07/minions1/original.jpg'
    // }
    ]